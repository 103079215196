@use "@/styles/variables" as *;

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWithIcon {
  padding: $space-xxxs;
  font-size: $font-size-md;
}

.miniCart {
  position: absolute;
  right: 0;
  top: $header-height;
  z-index: $mini-cart-layer;

  // This is the default "hidden" state:
  opacity: 0; // Completely transparent...
  transform: translateY(-10px); // ...translated a bit upwards..
  pointer-events: none; // ...and unclickable

  // Switch opacity gracefully
  transition: opacity 150ms, transform 150ms;

  // NOTE: Do NOT add `visibility: hidden` here since it breaks the transition.

  &.visible {
    // This is the "visible" state:
    opacity: 1; // Completely opaque
    transform: translateY(0); // ...no translation...
    pointer-events: all; // ...and clickable.
  }
}
