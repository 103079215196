@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$size-factor: 10px !default;
$color-search-primary-background: $color-transparent !default;
$color-search-primary-foreground: $color-primary !default;
$color-search-primary-placeholder: $color-primary !default;
$color-search-primary-border: $color-primary !default;
$color-search-secondary-background: $color-primary !default;
$color-search-secondary-foreground: $color-lighter-gray !default;
$color-search-secondary-placeholder: $color-primary !default;
$color-search-secondary-border: $color-transparent !default;

.primary {
  background-color: $color-search-primary-background;
  color: $color-search-primary-foreground;
  border: $color-search-primary-border;

  &::placeholder {
    color: $color-search-primary-placeholder;
  }
}

.secondary {
  background-color: $color-search-secondary-background;
  color: $color-search-secondary-foreground;
  border: $color-search-secondary-border;

  &::placeholder {
    color: $color-search-secondary-placeholder;
    font-weight: $font-weight-bold;
  }
}

.searchInput {
  width: 100%;
  height: $size-factor * 4;
  border: none;
  padding-left: $size-factor * 4;
  outline: none;
  backface-visibility: hidden;

  @include md-max {
    padding: $space-sm;
    height: 100%;
  }
}

:is(body) .btnIcon {
  position: absolute;
  inset-inline-start: 0;

  padding: $size-factor;

  transition: all 250ms ease-in-out;
  transition-property: inset-inline-start, translate;

  @include md-max {
    display: none;
  }
}

.form {
  position: relative;

  &:focus-within {
    .btnIcon {
      inset-inline-start: 100%;
      translate: -100%;
    }

    .searchInput {
      padding-left: $size-factor;
      padding-right: $size-factor * 3;
      border: 1px solid transparent;

      @include md-max {
        padding: $space-sm;
        border: none;
      }
    }
  }
}
