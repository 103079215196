@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.productList {
  overflow-y: auto;
}

:is(body) {
  .editModal {
    overflow-y: auto;
    max-width: unset;

    @include sm-min {
      width: min(1200px, 95vw);
    }
  }
}
