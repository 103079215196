////
/// Defines variables relating to site-wide font face usage.
/// **Note:** Font-face definitions are found in `src/styles/core/_fonts.scss`.
/// @group variables/fonts
////

@use '@/styles/utils/system-font' as *;

@forward "@/base/styles/variables/fonts" with (
  // Site-wide font face definitions.
  $body-font: ("Haas Grotesk Display Web", $system-font),

  // Heading fonts
  $heading-font: ("Haas Grotesk Display Web", $system-font),
  $heading1-font: ("Haas Grotesk Display Web", $system-font),
  $heading2-font: ("Haas Grotesk Display Web", $system-font),
  $heading3-font: ("Haas Grotesk Display Web", $system-font),
  $heading4-font: ("Haas Grotesk Display Web", $system-font),
  $heading5-font: ("Haas Grotesk Display Web", $system-font),
  $heading6-font: ("Haas Grotesk Display Web", $system-font)
);
