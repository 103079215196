////
/// Defines transitions and animations commonly used throughout the site.
/// @group utils/transitions
////
@use "@/styles/variables" as *;

/**
  * @function transition
  * @description A mixin to apply a transition to an element. This mixin
  * can be passed properties as arguments which apply a single transition
  * duration.
  * @param {Number} $duration - The duration of the transition in milliseconds.
  * @param {String} $function - The timing function of the transition.
  * @param {String} $properties - The properties to transition.
  */
@mixin transition(
  $duration: $transition-duration,
  $function: $transition-timing-function,
  $properties...
) {
  @if length($properties) < 1 {
    $properties: all;
  }
  transition-property: $properties;
  transition-duration: $duration;
  transition-timing-function: $function;
}

@mixin button-transition(
  $duration: $transition-duration-fast-3,
  $function: $transition-timing-function,
  $properties...
) {
  @if length($properties) < 1 {
    $properties: color, background-color, border-color;
  }

  @include transition($duration, $function, $properties...);
}
