@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 360px;
  height: 660px;

  /**
  * On smaller laptops the minicart checkout button can become unreachable. This
  * keeps the minicart bound to the constraints of the viewport's height minus the
  * height of the header minus 40px of padding
  */
  max-height: calc(100vh - #{$header-height} - 40px);
  border: 1px solid $color-light-gray-5;
  box-shadow: rgb(128 128 128) 0 2px 10px 0;
  color: $color-black;
  background: $color-white;
  z-index: $mini-cart-layer;
}

.cart {
  position: relative;
  height: 100%;
  z-index: 4;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-sm;
  padding: $space-sm $space-sm 0;
}

:is(body) .viewBasket {
  font-weight: $font-weight-bold;
  text-decoration: underline;
}

.errorAlertContainer {
  margin: $space-sm;
  border: 1px $color-danger-900 solid;
}

.errorAlertMessage {
  font-size: $font-size-sm;
  font-weight: normal;
}

.productList {
  width: 100%;
  padding: 0 $space-sm $space-sm;
  height: calc(100% - 175px);
  z-index: 2;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.productList::-webkit-scrollbar {
  display: none;
}

.cartFooter {
  padding: $space-sm $space-md;
  background-color: $color-white;
  border-top: 1px solid $color-light-gray-5;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 4;
}

.totalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-md;

  .skeletonLoader {
    width: 25%;
    height: $font-size-md;
  }
}

.total {
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  margin: 0;
}

.totalAmount {
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;
  margin: 0;
}

:is(body) {
  .editModal {
    width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;

    @include xs-max {
      width: 100vw;
    }

    @include sm-min {
      width: min(900px, 95vw);
      height: auto;
    }
  }

  .modalHeader {
    background-color: $color-gray-light;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;
    position: sticky;
    top: 0;
  }
}

.modalBody {
  padding: $space-sm;
}

.yourCart {
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  text-transform: uppercase;
}

.paymentButtons {
  display: flex;
  flex-direction: column;
  gap: $space-xxs;
}
