// Layers to be used as z-index values.
$modal-layer: 1501;
$disabled-layer: -1;

// Header
$header-layer: 1500;
$notification-layer: 1600;
$notification-content-layer: 991;
$mini-cart-layer: 2000;
$search-layer: 100;
$user-layer: 100;

// Footer
$cart-footer-layer: 10;
