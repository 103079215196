@use "@/styles/variables" as *;
@use "@/styles/utils" as *;
/// The amount of time the badge takes to enter and exit when animating

@forward "./styles.base.module.scss";

$badgeTransitionDuration: 200ms !default;

.cartBadge {
  top: -5px;
  right: -15px;
  font-weight: $font-weight-base;
  border: none;

  @include transition(
    $badgeTransitionDuration,
    $transition-timing-function,
    opacity,
    transform
  );

  // Applied as the intial state when the badge is being shown.
  &-enter {
    transform: scale(0.65);
    opacity: 0;
  }

  // Applied while the badge is entering and as the final state.
  &-enter-active,
  &-enter-done {
    transform: scale(1);
    opacity: 1;
  }

  // Applied as the intial state when the badge is being hidden.
  &-exit {
    transform: scale(1);
    opacity: 1;
  }

  // Applied while the badge is exiting and as the final state.
  &-exit-active,
  &-exit-done {
    transform: scale(0.65);
    opacity: 0;
  }
}

.cartIcon {
  @include sm-max {
    width: 21px;
  }
}

:export {
  badgeTransitionDuration: $badgeTransitionDuration;
}
