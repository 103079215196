@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

/* The navbar styling (Navigation links) */
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-right: auto;
  height: 100%;
}

.navItem {
  display: flex;
  height: 100%;

  & > a {
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 20%;
      height: 2px;
      width: 70%;
      background-color: currentColor;

      opacity: 0;
      transition: 150ms;
    }
  }
}

.navItemDropdownVisible {
  & > a {
    &::before {
      opacity: 1;
    }
  }
}

body .navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-header-foreground;
  text-decoration: none;
  padding: 0 $space-sm;

  // Balboa font is very weird. Text didn't look centered
  padding-top: 0.4em;
  text-transform: uppercase;
  font-family: $heading-font;
  font-weight: $font-weight-base;
  letter-spacing: $letter-spacing-headline;
  font-size: $font-size-base;
}

.dropdownIcon {
  margin-left: 0.5em;

  // Contradicting the Balboa font effect for .navLink
  margin-top: -0.1em;
}

.dropdown {
  display: none;

  flex-direction: row;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1;
  background: rgb(255 255 255);
  padding: $space-md $space-xxl;
  border-bottom: 1px solid rgb(0 0 0 / 10%);

  opacity: 0;
  pointer-events: none;

  transition: 150ms;
}

.dropdownVisible {
  display: flex;
  pointer-events: all;
  opacity: 1;
}

.dropdownExitPrepare {
  display: flex;
  opacity: 1;

  // To allow the user to see the cool effect on the sections
  transition: 200ms;
  transition-delay: 0.1s;

  @media (prefers-reduced-motion) {
    transition: none !important;
    opacity: 1 !important;
  }
}

.dropdownExiting {
  opacity: 0;
}

.section {
  display: flex;
  flex-direction: column;
  transition: all 300ms;
  opacity: 0;
  position: relative;
  top: 30px;

  &:not(:last-child) {
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid rgb(0 0 0 / 10%);
  }

  @media (prefers-reduced-motion) {
    transition: none !important;
    top: 0;
    opacity: 1;
  }
}

.sectionEntered {
  top: 0;
  opacity: 1;
}

.block {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.blockHeader {
  margin: 0;
  margin-bottom: 10px;
  letter-spacing: $letter-spacing-headline;
  font-size: 16px;
  font-weight: 600;
  font-family: $heading-font;
  color: $color-navigation-foreground;
  text-transform: uppercase;
}

.blockLinks {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.blockLinkWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.blockLink {
  font-weight: normal;
  padding: $space-xxxs 0;
  text-decoration: none;
  color: $color-navigation-foreground;
  white-space: nowrap;
  line-height: $line-height-relaxed;
  font-family: $body-font;

  &:hover {
    color: $color-header-foreground-hover;
  }
}
