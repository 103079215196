@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $size-factor: 8px,
  $color-search-primary-background: $color-white,
  $color-search-primary-placeholder: $color-primary-100,
  $color-search-primary-border: $color-black,
  $color-search-secondary-background: $color-white,
  $color-search-secondary-foreground: $color-shading-900,
  $color-search-secondary-placeholder: $color-shading-900,
  $color-search-secondary-border: none
);

@use "./styles.base.module.scss" as *;

.searchInput {
  padding-top: $size-factor / 2;
}

:is(body) .btnIcon {
  @include md-max {
    display: block;
  }
}
