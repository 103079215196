////
/// Defines variables relating to global site layout and structure.
/// @group variables/layout
////
@use "./colors" as *;

@forward "@/base/styles/variables/layout" with (
  // Site header layout
  $header-height: 90px,
  $header-height-desktop: 130px,
  $header-loading-bar-color: $color-secondary,
  $header-icon-size: 35px
);

// For content in right column of PDP
$max-product-info-width: calc(940px - 7vw);
