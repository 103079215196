@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$inverted-border-gray: #333;

.container {
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 90%;
  margin: $space-xl auto;

  @include md-max {
    padding: 0 $space-md;
    margin: $space-lg auto 50px;
  }

  @include xs-max {
    margin: $space-md auto;
    padding: 0 $space-lg;
    flex-direction: column;
  }
}

.content {
  width: 55%;

  @include sm-max {
    width: 100%;
  }
}

.titleText {
  font-family: $heading2-font;
  font-size: $font-size-lg;
  color: $color-shading-800;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
  letter-spacing: $letter-spacing-headline;
  font-weight: $font-weight-base;
}

.text {
  margin-bottom: $space-sm;
  display: block;
}

.searchTitle {
  font-family: $heading2-font;
  color: $color-shading-800;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-headline;
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
}

.searchContainer {
  width: 100%;

  @include md-min {
    width: 290px;
  }

  svg {
    margin-right: $space-xxs;
  }

  input {
    font-family: $body-font;
    border: 1px solid $color-border;
    outline: none;

    &::placeholder {
      color: $color-black;
      font-weight: $font-weight-bold;
    }
  }
}

.placementContainer {
  width: 45%;
  padding: 40px 40px 0;

  @include md-max {
    width: 100%;
    padding: 20px 20px 0;
  }

  @include sm-max {
    width: 100%;
  }

  @include xs-max {
    margin: 30px auto 35px;
  }
}
