@use "@/styles/variables" as *;

/// The amount of time the badge takes to enter and exit when animating
$badgeTransitionDuration: 0ms !default;

@keyframes entry {
  from {
    transform: rotateZ(-90deg);
    opacity: 0;
  }

  to {
    transform: rotateZ(0deg);
    opacity: 1;
  }
}

.buttonWithIcon {
  height: $header-icon-size;
  width: $header-icon-size;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-lg;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  color: inherit;
}

.buttonAnimatedEntry {
  animation: entry 200ms ease-out forwards;
}

.cartBadge {
  position: absolute;
  aspect-ratio: 1/1;
  top: 5px;
  right: 5px;
  font-size: $font-size-xs;
  color: $color-header-cart-badge-foreground;
  border: 2px solid $color-header-cart-badge-foreground;
  border-radius: 50%;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $font-size-xs + 8px;
  padding: 0 2px;
  background: $color-header-cart-badge-background;

  // Applied as the intial state when the badge is being shown.
  &-enter {
  }
  // Applied while the badge is entering.
  &-enter-active {
  }
  // Applied when the badge has finished entering as the final state.
  &-enter-done {
  }

  // Applied as the intial state when the badge is being hidden.
  &-exit {
  }
  // Applied while the badge is exiting.
  &-exit-active {
  }
  // Applied when the badge has finished exiting as the final state.
  &-exit-done {
  }
}

:export {
  badgeTransitionDuration: $badgeTransitionDuration;
}

.hideCount {
  opacity: 0;

  .countText {
    opacity: 0;
  }
}
